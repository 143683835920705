import { waveform } from "ldrs";
import { Paragraph } from "../Commons/Paragraph";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { FC } from "react";

waveform.register();

interface WaveProgressBarProps {
  text?: string;
  subText?: string | null | undefined;
  goBack?: () => void;
}

const WaveProgressBar: FC<WaveProgressBarProps> = ({
  text = "Calculating",
  subText,
  goBack,
}) => {
  return (
    <div className="w-full h-full flex items-center flex-col">
      <div className="p-5 w-full">
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer rounded-full w-14 h-14 flex justify-center items-center grayBtn"
            onClick={goBack}
          >
            <img src={ArrowBackIcon} alt="" />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col w-full h-4/5">
        <div className="p-6">
          <l-waveform
            size="70"
            stroke="5.5"
            speed="1.2"
            color="#6c2ce4"
          ></l-waveform>
        </div>
        <Paragraph
          text={text}
          props={{ fontSize: "20px", fontWeight: "600", textAlign: "center" }}
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
        {subText && (
          <Paragraph
            text={subText}
            props={{
              marginTop: "10px",
              fontSize: "12px",
              fontWeight: "400",
              textAlign: "center",
            }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        )}
      </div>
    </div>
  );
};

export default WaveProgressBar;

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import Null_player from "../../../../assets/imgs/null-player-img.svg";
import Select_player from "../../../../assets/imgs/select-player-icon.svg";
import Small_polygon from "../../../../assets/imgs/small_polygon.svg";
import { Paragraph } from "../../../Commons/Paragraph";
import { teamColors } from "../../../../config/constants/teamColors";
import cancelIcon from "../../../../assets/imgs/cancel-icon.png";
import { DataItem } from "../../../../Controllers/TradeAnalyzer/type";
import { useNavigate } from "react-router-dom";
import { mapWithFallback } from "../../../../lib/utils";

interface Category {
  name: string;
}

interface PositionItem {
  id: number;
  name: string;
}

interface Props {
  tableHeader: string[];
  data: DataItem[];
  selected: boolean;
  disabled: boolean;
  credit?: string;
  onSelectPlayer: (player: DataItem) => void;
}

const TablePlayers: React.FC<Props> = ({
  tableHeader,
  data,
  selected,
  disabled,
  credit = "",
  onSelectPlayer,
}) => {
  const navigate = useNavigate();

  const fetchFieldName = (item: DataItem) => {
    return item.playerName ? item.playerName : null;
  };
  const fetchFieldplayerTeam = (item: DataItem) => {
    return item.playerTeam ? item.playerTeam : null;
  };

  const onClickCell = async (player: DataItem) => {
    if (!disabled) {
      onSelectPlayer(player);
    }
  };

  return (
    <div className="relative ">
      {credit && (
        <Paragraph
          text={credit}
          props={{
            fontSize: "14px",
            lineHeight: "100%",
            fontWeight: "400",
            position: "absolute",
            top: "15px",
            right: "12px",
          }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
      )}
      {data?.length > 0 ? (
        <Table className=" overflow-x-auto w-full">
          <TableHeader>
            <TableRow className="hover:bg-transparent border-transparent">
              {tableHeader &&
                mapWithFallback(tableHeader).map((item, index) => (
                  <TableHead
                    key={"players-header-" + index}
                    className="text-white text-[16px] pb-2"
                    style={index !== 0 ? { minWidth: "150px" } : {}}
                  >
                    {item}
                  </TableHead>
                ))}
            </TableRow>
          </TableHeader>
          <TableBody className={`[&>*:nth-child(odd)]:bg-[#FFFFFF1A]`}>
            {mapWithFallback(data).map((item, index) => (
              <TableRow
                key={"player-body-" + item.roto_player_id}
                className={`hover:bg-transparent border-transparent bg-transparent text-white`}
                onClick={() => onClickCell(item)}
              >
                <TableCell
                  key={"player-cell-name-" + item.roto_player_id}
                  className="text-base font-medium"
                  style={{ width: "350px" }}
                >
                  <div
                    className="flex items-center gap-7"
                    onClick={(e) => {
                      e?.stopPropagation();
                      navigate(`/player-profile/${item.roto_player_id}`);
                    }}
                  >
                    <div className="relative">
                      <div
                        className="rounded-xl w-[49.2px] h-[49.2px] flex items-end overflow-hidden"
                        style={{
                          backgroundColor: item.playerTeamShort
                            ? teamColors[item.playerTeamShort]
                            : "#4F2683",
                        }}
                      >
                        <img
                          id={item.roto_player_id}
                          key={item.roto_player_id}
                          className={
                            item.playerHeadshotURL ? "scale-[1.88]" : "h-[45px]"
                          }
                          src={item.playerHeadshotURL || Null_player}
                          alt=""
                        />
                      </div>
                      <div className="absolute -bottom-0 -right-[10px]">
                        <div className="relative">
                          <img
                            src={Small_polygon}
                            alt=""
                            className="w-[20px] h-[20px]"
                          />
                          <Paragraph
                            text={item.playerPosition}
                            props={{
                              fontSize: "8px",
                              lineHeight: "100%",
                              fontWeight: "600",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                            ParagraphType="Pragragh22"
                            color="textPrimary"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <Paragraph
                        text={fetchFieldName(item)}
                        props={{ fontSize: "16px", fontWeight: "500" }}
                        ParagraphType="Pragragh22"
                        color="textPrimary"
                      />
                      <Paragraph
                        text={fetchFieldplayerTeam(item)}
                        props={{
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                        ParagraphType="Pragragh22"
                        color="backgroundGrayPrimary"
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  key={`player-cell-Select-${item.roto_player_id}`}
                  style={{ width: "100px" }}
                >
                  <div
                    className={` ${disabled ? "" : "cursor-pointer"}  w-[20px] h-[20px] flex items-end`}
                    style={disabled ? { opacity: "0.5" } : {}}
                  >
                    <img
                      id={"Select-" + item.roto_player_id}
                      key={"Select-" + item.roto_player_id}
                      src={selected ? cancelIcon : Select_player}
                      alt=""
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className=" p-10 flex justify-center mt-10">
          <Paragraph
            text={"--No Data Currently--"}
            props={{
              fontSize: "20px",
              lineHeight: "145%",
              letterSpacing: "-0.4px",
              opacity: "0.70",
            }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
        </div>
      )}
    </div>
  );
};

export default TablePlayers;

import React, { useEffect } from "react";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import AppleIcon from "../../assets/imgs/apple-icon.svg";
import GoogleIcon from "../../assets/imgs/google-icon.svg";
import phoneIcon from "../../assets/imgs/phone-icon.svg";

import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { Button } from "../ui/button";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { useNavigate } from "react-router-dom";
import LoginBannerImage from "../../assets/imgs/LoginBannerImage.svg";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";
import { isMobile } from "react-device-detect";
import { hapticsImpactLight } from "../../lib/utils";

export const Login: React.FC = () => {
  const { logEventAnalytics } = useFirebaseAnalytics();
  const navigate = useNavigate();
  const { signInWithGoogle, signInWithApple } = useSupabaseAuth();
  const { useAuthStore } = useSupabaseAuth();
  const user = useAuthStore((state: any) => state.user);

  const handleGoogleSignIn = async () => {
    console.log("handle google Sign In");
    logEventAnalytics("login_selected_google", {
      name: "Sign in with Google",
    });
    try {
      const response = await signInWithGoogle();
      console.log(response);
    } catch (error) {
      console.log("error handle google Sign In " + error);
    }
  };

  const handleAppleSignIn = async () => {
    console.log("handle Apple Sign In");
    logEventAnalytics("login_selected_apple", {
      name: "Sign in with Apple",
    });
    try {
      const response = await signInWithApple();

      console.log(response);
    } catch (error) {
      console.log("error handle Apple Sign In " + error);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col h-full justify-between md:w-[80%] max-w-[1024px] m-auto">
        <div className="flex items-center justify-between relative z-20">
          <div
            className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center grayBtn"
            onClick={() => {
              hapticsImpactLight();
              navigate("/", { state: { fromLogin: true } })
            }}
          >
            <img src={ArrowBackIcon} alt="" />
          </div>
          <Paragraph
            text="Log In"
            props={{ fontSize: "22px", marginLeft: "-45px" }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <div></div>
        </div>

        <div className=" sm:mt-1 flex flex-col items-center justify-center gap-6 text-center pt-5 flex-1">
          <div className=" relative h-full pt-8 md:pt-3 flex justify-center items-center">
            <img
              src={LoginBannerImage}
              alt=""
              className="h-full md:h-[249.464px] lg:min-w-[330.25px] md:min-w-[180px] sm:min-w-[260px] min-w-[233.417px] absolute top-[50%] left-[50%] -translate-x-[50%] z-10 -translate-y-[50%] "
            />
            <div className="h-[50%] max-h-[233.417px] md:h-[150.464px] md:w-[222.33px] w-[233.417px] chatbot-bot-bg"></div>
          </div>
          <div className=" mb-5 text-center">
            <div className="text-4xl text-white font-bold sm:text-4xl md:text-[42.75px]">
              Let’s kick off your winning season
            </div>
          </div>
        </div>

        <div className="w-full md:w-3/4 lg:w-2/4 mx-auto mt-7 md:mt-[2svh] lg:mt-[5svh]">
          <div className="w-full flex flex-col gap-6 md:gap-5 lg:gap-7">
            <Button className="h-12 gap-3 md:gap-4 lg:gap-5 text-base md:text-lg lg:text-xl"
              onClick={handleAppleSignIn}
              style={{
                boxShadow: "0px 18px 30px 0px rgba(131, 119, 198, 0.11)"
              }}
              variant={"lightGray"}
            >
              <img
                src={AppleIcon}
                alt="AppleIcon"
                className="w-[18.499px] md:w-6 lg:w-[30.125px]"
              />
              Continue with Apple
            </Button>

            <Button className="h-12 gap-3 md:gap-4 lg:gap-5 text-base md:text-lg lg:text-xl"
              onClick={handleGoogleSignIn}
              style={{
                boxShadow: "0px 18px 30px 0px rgba(131, 119, 198, 0.11)"
              }}
              variant={"lightGray"}
            >
              <img
                src={GoogleIcon}
                alt="GoogleIcon"
                className="w-[18.4px] md:w-6 lg:w-[30.125px]"
              />
              Sign up with Google
            </Button>

            <Button className="h-12 gap-3 md:gap-4 lg:gap-5 bg-transparent hover:bg-transparent text-base md:text-lg lg:text-lg"
              onClick={() => {
                logEventAnalytics("login_phone_input", {
                  name: "Sign in with phone number",
                });
                navigate("/verify-phone-number")
              }}
            >
              <img
                src={phoneIcon}
                alt="phoneIcon"
                className="w-[15.499px] md:w-6 lg:w-[30.125px]"
              />
              Continue with Phone Number
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

import HttpRequestLeagueSync from "../../Services/HttpRequestLeagueSync";

export const enableEspnSync = async (
  user_id: string,
  esp_swid: string,
  espn_s2: string,
  years: [string],
  league_IDs: [string],
  id: string | null,
  fantasy_type: string | null,
  luegue_uuid_esp: string | null,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/v1/user_sync/user_sync_espn`,
      data: {
        esp_swid,
        espn_s2,
        years,
        league_IDs,
        user_id,
        id: id ? id : undefined,
        fantasy_type: fantasy_type ? fantasy_type : undefined,
        luegue_uuid_esp: luegue_uuid_esp ? luegue_uuid_esp : undefined,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const enableSleeperSync = async (
  user_id: string,
  sleeper_user_name: string,
  sleeper_league_id: [string] | null,
  sleeper_user_id: string | null,
  luegue_uuid_sleeper: string | null,
  id: string | null,
  fantasy_type: string | null,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/v1/user_sync/user_sync_sleeper`,
      data: {
        user_id,
        sleeper_league_id,
        sleeper_user_id,
        sleeper_user_name,
        luegue_uuid_sleeper: luegue_uuid_sleeper
          ? luegue_uuid_sleeper
          : undefined,
        id: id ? id : undefined,
        fantasy_type: fantasy_type ? fantasy_type : undefined,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const enableYahooSync = async (user_id: string, code: string) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/yahoo-credentials`,
      data: {
        user_id,
        code,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const getSyncByUser = async (userId: string) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "GET",
      url: `/v1/user_sync/${userId}`,
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const getSyncByUserAndFantasyType = async (
  userId: string,
  FantasyType: string,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "GET",
      url: `/v1/user_sync/${userId}/${FantasyType.trim().toUpperCase()}`,
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const SleeperLeagueSync = async (
  user_id: string,
  user_settings_id: string,
  league_id: string,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/v1/user_sync/lueague_sync_sleeper`,
      data: {
        user_id,
        user_settings_id,
        league_id,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const SleeperLeagueUnSync = async (
  user_id: string,
  user_settings_id: string,
  league_id: string,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/v1/user_sync/lueague_unsync_sleeper`,
      data: {
        user_id,
        user_settings_id,
        league_id,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const YahooLeagueSync = async (
  user_id: string,
  user_settings_id: string,
  league_id: string,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/lueague_sync_yahoo`,
      data: {
        user_id,
        user_settings_id,
        league_id,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const YahooLeagueUnSync = async (
  user_id: string,
  user_settings_id: string,
  league_id: string,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/lueague_unsync_yahoo`,
      data: {
        user_id,
        user_settings_id,
        league_id,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

import { teamColors } from "../../config/constants/teamColors";

export const leagueTableHeaders = ["Pos.", "Player", "RotoBot Score", "Pos. Rank", "Trade Value"];

export const leagueTableData = [
    {
      pos: "WR",
      player: "Justin Jefferson",
      avgPPG: 12.1,
      posRank: 8,
      tradeValue: 960,
      url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/CeeDLamb1.webp",
      color: teamColors["MIN"]
    },
    {
      pos: "QB",
      player: "Patrick Mahomes",
      avgPPG: 13.2,
      posRank: 24,
      tradeValue: 837,
      url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/DeviSing1.webp",
      color: teamColors["GB"]
    },
    {
      pos: "WR",
      player: "Tyreek Hill",
      avgPPG: 18.3,
      posRank: 14,
      tradeValue: 960,
      url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/CoopKupp1.webp",
      color: teamColors["KC"]
    },
    {
      pos: "QB",
      player: "Lamar Jackson",
      avgPPG: 10.2,
      posRank: 6,
      tradeValue: 1930,
      url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/JoshDown1.webp",
      color: teamColors["BAL"]
    },
    {
      pos: "WR",
      player: "Davante Adams",
      avgPPG: 22.3,
      posRank: 4,
      tradeValue: 960,
      url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/TyleBoyd1.webp",
      color: teamColors["GB"]
    },
    {
      pos: "RB",
      player: "Saquon Barkley",
      avgPPG: 10.1,
      posRank: 5,
      tradeValue: 973,
      url: "https://playerprofilephotos.blob.core.windows.net/profile-photos/MarkAndr1.webp",
      color: teamColors["NYG"]
    }
];
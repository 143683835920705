import React from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import { useNavigate } from "react-router-dom";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";
import CelebrationAnimation from "../../assets/animations/celebration_animation.json";
import { Button } from "../ui/button";

const Congratulations = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="flex flex-col h-full justify-between">
        <div
          className={`flex items-center justify-between w-full overflow-hidden h-[58px]`}
        >
          <div
            className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center transition-all duration-500 ease-in-out"
            style={{
              background: Colors.backgroundSecundary,
            }}
            onClick={() => navigate("/home")}
          >
            <img src={ArrowBackIcon} alt="" />
          </div>

          <div className="cursor-pointer" onClick={() => navigate("/home")}>
            <Paragraph
              text="Skip"
              props={{ fontSize: "20px" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        </div>
        <div className="text-center">
          <div className="flex-1 flex items-center -mx-5 max-h-[390.342px]">
            <LottieAnimation animationData={CelebrationAnimation} />
          </div>
          <Paragraph
            text="Congratulations"
            ParagraphType="Pragragh22"
            color="textPrimary"
            className="text-4xl font-medium"
          />
          <Paragraph
            text="You have synced your leagues from Sleeper. "
            ParagraphType="Pragragh22"
            color="textPrimary"
            className="text-base w-[70%] m-auto"
            props={{ color: Colors.textLigth }}
          />
        </div>
        <Button
          className="w-full h-[60px] text-base"
          onClick={() => navigate("/home")}
        >
          Continue
        </Button>
      </div>
    </Layout>
  );
};

export default Congratulations;

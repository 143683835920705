import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Colors } from "../Colors/Colors";
import { hapticsImpactLight, mapWithFallback } from "../../../lib/utils";

interface TabItem {
  label: string;
  content: React.ReactNode;
}

interface GenericTabsProps {
  tabs: TabItem[];
  onTabChange?: (selectedIndex: number) => void;
}

const TabPanel: React.FC<{
  value: number;
  index: number;
  children: React.ReactNode;
}> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      className="mt-11"
    >
      {value === index && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.8 }}
          className="text-white"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
};

const GenericTabsComponent: React.FC<GenericTabsProps> = ({
  tabs,
  onTabChange,
}) => {
  const [value, setValue] = useState(0);
  const [underlineStyle, setUnderlineStyle] = useState({ left: 30, width: 50 });
  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleChange = (index: number, tabRef: HTMLDivElement | null) => {
    hapticsImpactLight()
    setValue(index);
    if (onTabChange) {
      onTabChange(index);
    }
    if (tabRef) {
      if(window.innerWidth > 1040){
        setUnderlineStyle({
          left: tabRef.offsetLeft + 3,
          width: tabRef.offsetWidth,
        });        
      }else{
        setUnderlineStyle({
          left: tabRef.offsetLeft + 2,
          width: tabRef.offsetWidth,
        });
      }
    }
  };

  // useEffect(() => {
  //   if (onTabChange) {
  //     onTabChange(value);
  //   }
  // }, [value, onTabChange]);

  useEffect(() => {
    const firstTabRef = tabRefs.current[0];
    if (firstTabRef) {
      if(window.innerWidth > 640){
      setUnderlineStyle({
        left: firstTabRef.offsetLeft -5,
        width: firstTabRef.offsetWidth,
      });
    }else{
      setUnderlineStyle({
        left: firstTabRef.offsetLeft,
        width: firstTabRef.offsetWidth,
      });
    }
    }
  }, []);

  return (
    <div className="w-full">
      <div className="relative">
        <div className="relative flex justify-between rounded-lg sm:w-[85%] w-[88%] m-auto px-3 sm:px-0 sm:pl-[14px]">
          {mapWithFallback(tabs).map((tab, index) => (
            <div
              key={index}
              ref={(el) => (tabRefs.current[index] = el)}
              className={`cursor-pointer py-2 text-white text-xs sm:text-lg lg:text-3xl font-bold uppercase text-center`}
              onClick={() => handleChange(index, tabRefs.current[index])}
              id={`custom-tab-${index}`}
              aria-controls={`custom-tabpanel-${index}`}
            >
              <div className={`${index ===2 && "sm:pr-2"}`}>
              {tab.label}
              </div>
            </div>
          ))}
          <motion.div
            className="flex justify-center absolute -bottom-2 sm:-bottom-3"
            animate={underlineStyle}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <motion.div className="h-[6px] md:h-2 min-w-[102px] md:min-w-[120px] lg:min-w-[156.25px] m-auto bg-[#3EC4FF] rounded-lg" />
          </motion.div>
        </div>
        <div
          className="h-[6px] md:h-2 w-[94%] md:w-[88%] lg:w-[85%] rounded-[7px] absolute -bottom-2 sm:-bottom-3 left-1/2 -translate-x-1/2"
          style={{
            background: Colors.backgroundSecundary,
          }}
        ></div>
      </div>
      {mapWithFallback(tabs).map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default GenericTabsComponent;

import { useState, useEffect } from "react";
import { useSupabaseAuth } from "../Context/AuthContext/AuthContext";
import { fetchLeagueSyncByUser } from "../Controllers/MyLeagueSync/index";
import create from "zustand";
import { persist } from "zustand/middleware";

const useStore = create<any>(
  persist(
    (set) => ({
      objectSync: null,
      setObject: (data: any) => set({ objectSync: data }),
      clearObject: () => set({ objectSync: null }),
    }),
    {
      name: "user-sync",
      getStorage: () => localStorage,
    },
  ),
);
const useUserLeagueSync = () => {
  const { useAuthStore } = useSupabaseAuth();
  const userStorage = useAuthStore.getState()?.user;
  const [leagueSync, setLeagueSync] = useState<any>(null);
  const { setObject, objectSync } = useStore();

  const fetchLeagueSync = async (userStorage: any) => {
    const user_id = userStorage?.user?.id
      ? userStorage?.user?.id
      : userStorage?.user?.user?.id;

    const response = await fetchLeagueSyncByUser(user_id);

    if (response?.status) {
      setLeagueSync(response?.data?.data);
      setObject(response?.data?.data);
    }
  };

  useEffect(() => {
    if (userStorage) {
      fetchLeagueSync(userStorage);
    }
  }, [userStorage]);

  return { leagueSync };
};

export default useUserLeagueSync;

import React from "react";
import Chatbot_Disclaimer from "../../assets/imgs/LoginBannerImage.svg";
import tick from "../../assets/imgs/tick.svg";
import CancelIcon from "../../assets/imgs/cancel-icon.png";
import { Layout } from "../Layouts/Layout";
import { Paragraph } from "../Commons/Paragraph";
import { Colors } from "../Commons/Colors/Colors";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { mapWithFallback } from "../../lib/utils";

const features = [
  "Analyze player metrics and projections",
  "Evaluate & Craft Trade Proposals",
  "Assess Team Strengths & Weaknesses",
  "Make Smart Sit / Start Decisions",
  "Sift through the Waiver Wire",
];

const FeatureItem: React.FC<{ text: string }> = ({ text }) => (
  <div className="flex gap-3">
    <div
      className="w-6 h-6 rounded-full flex justify-center items-center flex-shrink-0"
      style={{ backgroundColor: Colors.backgroundTertiary }}
    >
      <img src={tick} alt="" />
    </div>
    <div className="mt-[6px]">
      <Paragraph
        text={text}
        props={{ fontSize: "12px", fontWeight: 700, lineHeight: "120%" }}
        ParagraphType="Pragragh22"
        color="textPrimary"
      />
    </div>
  </div>
);

const ChatbotDisclaimer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="flex flex-col justify-between h-full">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] self-end flex justify-center items-center shrink-0"
          style={{
            background: Colors.backgroundSecundary,
          }}
          onClick={() => navigate("/chat/new", { state: { fromLogin: true } })}
        >
          <img src={CancelIcon} alt="" />
        </div>
        <div className="flex justify-center w-full">
          <div className=" relative mt-1">
            <img
              src={Chatbot_Disclaimer}
              alt=""
              className="min-w-[173.907px] absolute top-[-60px] sm:-top-[40px] left-[50%] -translate-x-[50%] z-10 "
            />
            <div className="h-[123.907px] w-[186.734px] chatbot-bot-bg"></div>
          </div>
        </div>
        <div className="text-center mt-5">
          <Paragraph
            text="How Our Chatbot Helps You Win"
            props={{ fontSize: "32px", fontWeight: 700, lineHeight: "120%" }}
            ParagraphType="Pragragh22"
            color="textPrimary"
          />
          <div className="mt-2 opacity-70 px-5">
            <Paragraph
              text="Access more details about the chatbot’s capabilities in the “Tips” section of the menu."
              props={{ fontSize: "13px", fontWeight: 400, lineHeight: "145%" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
          <div
            className="px-3 py-5 mt-4 rounded-xl flex flex-col gap-4"
            style={{ backgroundColor: Colors.backgroundSecundary }}
          >
            {mapWithFallback(features).map((feature, index) => (
              <FeatureItem key={index} text={feature} />
            ))}
          </div>
          <Button
            className="text-[18px] font-medium mt-6"
            onClick={() =>
              navigate("/chat/new", { state: { fromLogin: true } })
            }
          >
            Get Started
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default ChatbotDisclaimer;

import { useLocation, useRoutes, Navigate } from "react-router-dom";
import { Chatbot } from "../Components/Chatbot";
import { Login } from "../Components/Login";
import { SignInWithApple } from "../Components/SignInWithApple";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { LandingPage } from "../Components/Landing";
import { MainLayout } from "../Components/Layouts";
import { OnBoarding } from "../Components/OnBoarding";
import { TeamAnalizer } from "../Components/TeamAnalizer";
import { VerificationPhoneNumber } from "../Components/VerificationPhoneNumber";
import Rankings from "../Components/Rankings/Rankings";
import PlayerProfile from "../Components/PlayerProfile/PlayerProfile";
import Research from "../Components/Research/Research";
import HandleAuth from "../Components/HandleAuth";
import Home from "../Components/Home";
import UserProfile from "../Components/UserProfile/UserProfile";
import Settings from "../Components/Settings/Settings";
import Subscription from "../Components/Subscription/Subscription";
import Membership from "../Components/Membership/Membership";
import AddCard from "../Components/AddCard/AddCard";
import Notifications from "../Components/Notifications/Notifications";
import { log } from "@capacitor/assets/dist/util/log";
import FAQs from "../Components/FAQs/FAQs";
import ChatbotModes from "../Components/ChatbotModes/ChatbotModes";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import BgMobile from "../assets/imgs/BG.png";
import { UserDetails } from "../Components/UserDetails/UserDetails";
import { isMobile } from "react-device-detect";
import ChatbotDisclaimer from "../Components/ChatbotDisclaimer/ChatbotDisclaimer";
import Capabilities from "../Components/Capabilities/Capabilities";
import AppletPay from "../Components/Subscription/AppletPay";
import ChatbotPaywall from "../Components/ChatbotPaywall/ChatbotPaywall";
import { Capacitor } from "@capacitor/core";
import TradeAnalyzer from "../Components/TradeAnalyzer/TradeAnalyzer";
import SitStartDecider from "../Components/SitStartDecider/SitStartDecider";
import { PlatformSelector } from "../Components/PlatformSelector/PlatformSelector";
import { LeagueSync } from "../Components/LeagueSync/LeagueSync";
import Oauth2 from "../Components/Yahoo/oauth";
import LeagueHubMyTeam from "../Components/LeagueHubTeam/LeagueHubTeam";
import Congratulations from "../Components/Congratulations/Congratulations";

export const AppRoutes = () => {
  const location = useLocation();

  const routes = useRoutes([
    {
      path: "/",
      element: <HandleAuth />,
    },
    {
      path: "/user-details",
      element: <UserDetails />,
    },
    {
      path: "/landing",
      element: (
        <PublicRoute>
          <LandingPage />
        </PublicRoute>
      ),
    },
    {
      path: "/on-boarding",
      element: isMobile ? (
        <PublicRoute>
          <OnBoarding />
        </PublicRoute>
      ) : (
        <Navigate to="/welcome" replace />
      ),
    },
    {
      path: "/welcome",
      element: Capacitor.isNativePlatform() ? (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ) : (
        <Login />
      ),
    },
    {
      path: "/verify-phone-number",
      element: (
        <PublicRoute>
          <VerificationPhoneNumber />
        </PublicRoute>
      ),
    },
    {
      path: "/apple",
      element: (
        <PublicRoute>
          <SignInWithApple />
        </PublicRoute>
      ),
    },
    {
      path: "/rankings",
      element: (
        <PrivateRoute>
          <Rankings />
        </PrivateRoute>
      ),
    },
    {
      path: "/player-profile/:id",
      element: (
        <PrivateRoute>
          <PlayerProfile />
        </PrivateRoute>
      ),
    },
    {
      path: "/player/:playerId/chat/:id",
      element: (
        <PrivateRoute>
          <MainLayout>
            <Chatbot />
          </MainLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/chat/:id",
      element: (
        <PrivateRoute>
          <MainLayout>
            <Chatbot />
          </MainLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/team-analizer",
      element: (
        <PrivateRoute>
          <MainLayout>
            <TeamAnalizer />
          </MainLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/home",
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },
    {
      path: "/player-profile/:id/research",
      element: (
        <PrivateRoute>
          <Research />
        </PrivateRoute>
      ),
    },
    {
      path: "/user-profile",
      element: (
        <PrivateRoute>
          <UserProfile />
        </PrivateRoute>
      ),
    },
    {
      path: "/settings",
      element: (
        <PrivateRoute>
          <Settings />
        </PrivateRoute>
      ),
    },
    {
      path: "/subscription",
      element: (
        <PrivateRoute>
          <Subscription />
        </PrivateRoute>
      ),
    },
    {
      path: "/membership",
      element: (
        <PrivateRoute>
          <Membership />
        </PrivateRoute>
      ),
    },
    {
      path: "/add-card",
      element: (
        <PrivateRoute>
          <AddCard />
        </PrivateRoute>
      ),
    },
    {
      path: "/notifications",
      element: (
        <PrivateRoute>
          <Notifications />
        </PrivateRoute>
      ),
    },
    {
      path: "/FAQs",
      element: (
        <PrivateRoute>
          <FAQs />
        </PrivateRoute>
      ),
    },
    {
      path: "/tips",
      element: (
        <PrivateRoute>
          <ChatbotModes />
        </PrivateRoute>
      ),
    },
    {
      path: "/chatbot-disclaimer",
      element: (
        <PrivateRoute>
          <ChatbotDisclaimer />
        </PrivateRoute>
      ),
    },
    {
      path: "/apple-pay",
      element: (
        <PrivateRoute>
          <AppletPay />
        </PrivateRoute>
      ),
    },
    {
      path: "/chatbot-paywall",
      element: (
        <PrivateRoute>
          <ChatbotPaywall />
        </PrivateRoute>
      ),
    },
    {
      path: "/platform-selector",
      element: (
        <PrivateRoute>
          <PlatformSelector />
        </PrivateRoute>
      ),
    },
    {
      path: "/trade-analyzer",
      element: (
        <PrivateRoute>
          <TradeAnalyzer />
        </PrivateRoute>
      ),
    },
    {
      path: "/league-sync/:FantasyType",
      element: (
        <PrivateRoute>
          <LeagueSync />
        </PrivateRoute>
      ),
    },
    {
      path: "/sit-start-decider",
      element: (
        <PrivateRoute>
          <SitStartDecider />
        </PrivateRoute>
      ),
    },
    {
      path: "/Yahoo",
      element: (
        <PrivateRoute>
          <Oauth2 />
        </PrivateRoute>
      ),
    },
    {
      path: "/my-team-league-sync",
      element: (
        <PrivateRoute>
          <LeagueHubMyTeam />
        </PrivateRoute>
      ),
    },
    {
      path: "/congratulations",
      element: (
        <PrivateRoute>
          <Congratulations />
        </PrivateRoute>
      ),
    },
  ]);

  console.log("routes", routes);
  console.log(location.pathname);

  const routesWithOutAnimation = [""];

  const matchPath = (path: any, pattern: any) => {
    const regex = new RegExp(`^${pattern.replace(/:\w+/g, "\\w+")}$`);
    console.log(
      `Matching path: ${path} with pattern: ${pattern} as regex: ${regex}`,
    );
    return regex.test(path);
  };

  const currentPath = location.pathname;

  console.log(`Current path: ${currentPath}`);

  const shouldAnimate = !routesWithOutAnimation.some((pattern) =>
    matchPath(currentPath, pattern),
  );

  console.log("shouldAnimate", shouldAnimate);

  return (
    <div
      style={{
        background: `#000513 url(${BgMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        boxSizing: "border-box",
        width: "100vw",
        overflowY: "auto",
        height: "100svh",
      }}
      className="no-select"
    >
      {/* {shouldAnimate ? (
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames="page"
            unmountOnExit
            timeout={800}
          >
            <div className="page w-full">{routes}</div>
          </CSSTransition>
        </TransitionGroup>
      ) : ( */}
      <div className="page w-full">{routes}</div>
      {/* )} */}
    </div>
  );
};

import { Paragraph } from "../../Commons/Paragraph";

//icons
import ArrowBackIcon from "../../../assets/imgs/arrow-back-left.svg";
import MenuLineIcon from "../../../assets/imgs/menu-line.svg";

//style
import { HeaderStyle } from "./HeaderStyle";

import { HeaderProps } from "./type";
import { useNavigate, useParams } from "react-router-dom";
import { Colors } from "../../Commons/Colors/Colors";
import useFirebaseAnalytics from "../../../hooks/useFirabaseAnality";
import { useLocation } from "react-router-dom";
import { hapticsImpactLight } from "../../../lib/utils";
import LeagueTeamSelect from "../../Commons/LeagueTeamSelect/LeagueTeamSelect";
import useUserLeagueSync from "../../../hooks/useLeagueSync";
import { useEffect } from "react";

export const Header: React.FC<HeaderProps> = ({ onClickMenu }) => {
  const navigate = useNavigate();
  const { id, playerId } = useParams();
  const { logEventAnalytics } = useFirebaseAnalytics();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const route = params.get("route");
  const { leagueSync } = useUserLeagueSync();

  const handleGoBack = () => {
    hapticsImpactLight();
    if (playerId) {
      logEventAnalytics("chat_nav_back ", {
        name: "Chatbot return back",
        playerID: playerId,
      });
      if (route === "ranking") {
        navigate(
          `/player-profile/${playerId}${route ? `?route=${route}` : ""}`,
        );
      } else {
        navigate(`/player-profile/${playerId}`);
      }
    } else {
      logEventAnalytics("chat_nav_back ", {
        name: "Chatbot return back",
      });
      navigate("/home");
    }
  };

  useEffect(() => {
    localStorage.setItem("ToogleSelected", JSON.stringify({}));
  }, []);

  const handleSelectTeam = (value: any) => {
    localStorage.setItem("ToogleSelected", JSON.stringify(value));
  };

  return (
    <div className="flex items-center justify-between px-5 pt-5">
      <div
        className="cursor-pointer relative z-10 grayBtn"
        style={HeaderStyle.icon}
        onClick={handleGoBack}
      >
        <img src={ArrowBackIcon} alt="" />
      </div>

      {leagueSync?.has_league_processed === true && !playerId ? (
        <div className="relative min-w-[160px] min-h-[45px]">
          <div className=" absolute top-0 left-0 w-full">
            <LeagueTeamSelect
              enabledAddNewTeam={false}
              placeholder={"Grade my Team"}
              options={
                leagueSync?.data?.leagues_array
                  ? leagueSync?.data?.leagues_array.filter(
                      (item: any) => item?.databrick_sync === true,
                    )
                  : []
              }
              onSelect={(value) => {
                handleSelectTeam(value);
              }}
            />
          </div>
        </div>
      ) : (
        <div className=" text-[22px] font-medium chatbot-header-text">
          RotoBot v1.0
        </div>
      )}
      <div
        className="cursor-pointer relative z-10 grayBtn"
        style={HeaderStyle.icon}
        onClick={onClickMenu}
      >
        <img src={MenuLineIcon} alt="" />
      </div>
      {/* <div className="text-xl"> Icono 2</div> */}
    </div>
  );
};

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";

interface TableProps {
  headings: string[];
  data: Record<string, any>[];
  column?: (string | ((element: any) => React.ReactNode))[];
}

const GenericTable: React.FC<TableProps> = ({
  headings,
  data,
  column = [],
}) => {
  return (
    <div className="overflow-x-auto no-scrollbar -mx-5">
      <Table className="min-w-full bg-transparent text-white no-scrollbar">
        <TableHeader>
          <TableRow className="hover:bg-transparent border-transparent">
            {headings.map((heading, index) => (
              <TableHead
                key={index}
                className="text-white text-[14px] pb-2 whitespace-nowrap"
              >
                {heading}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="[&>*:nth-child(odd)]:bg-[#FFFFFF1A]">
          {data?.map((element, rowIndex) => (
            <TableRow
              key={rowIndex}
              className="hover:bg-transparent border-transparent bg-transparent text-white"
            >
              {column.map((item, colIndex) => (
                <TableCell
                  key={colIndex}
                  className="font-medium text-[16px] whitespace-nowrap"
                //   style={{ minWidth: colIndex === 0 ? "" : colIndex === 1 ? "200px" :"" }}
                >
                  {typeof item === "string" ? element[item] : item(element)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default GenericTable;

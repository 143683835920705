import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import datalabelsPlugin from "chartjs-plugin-datalabels";
import { Paragraph } from "../Paragraph";
import { mapWithFallback } from "../../../lib/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  datalabelsPlugin,
);

interface LineChartProps {
  chartdata?: number[];
  color: string;
  title?: string;
  labels?: string[];
}

const LineChart: React.FC<LineChartProps> = ({
  chartdata = [],
  color,
  title,
  labels,
}) => {
  const labelArr = labels || mapWithFallback(chartdata).map(() => "");

  // Exclude the first and last points
  const dataSubset = chartdata.slice(1, -1);

  const nonZeroValues = dataSubset.filter((val) => val !== 0);
  const minValue = Math.min(...nonZeroValues);
  const maxValue = Math.max(...dataSubset);
  const minIndex = chartdata.indexOf(minValue);
  const maxIndex = chartdata.indexOf(maxValue);

  const data = {
    labels: labelArr,
    datasets: [
      {
        data: chartdata,
        borderColor: color,
        backgroundColor: color,
        fill: true,
        tension: 0.4,
        borderWidth: 2,
        pointBackgroundColor: "white",
        pointBorderColor: "white",
        pointRadius: 2,
        pointHoverRadius: 2,
      },
    ],
  };

  const options = {
    clip: 1000,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      // annotation: {
      //   annotations: {
      //     avgLine: {
      //       // type: "line",
      //       scaleID: "y",
      //       value: (minValue + maxValue) / 2,
      //       borderColor: "white",
      //       borderWidth: 1,
      //       borderDash: [5, 5],
      //       label: {
      //         content: "Average",
      //         enabled: true,
      //         position: "end",
      //       },
      //     },
      //   },
      // },
      datalabels: {
        display: true,
        color: "white",
        font: {
          weight: 700,
        },
        align: (context: any) => {
          const value = context.dataset.data[context.dataIndex];
          if (value === minValue) {
            return "start";
          }
          if (value === maxValue) {
            return "end";
          }
          return "center";
        },
        anchor: (context: any) => {
          const value = context.dataset.data[context.dataIndex];
          if (value === minValue) {
            return "start";
          }
          if (value === maxValue) {
            return "end";
          }
          return "center";
        },
        formatter: (value: any, context: any) => {
          const index = context.dataIndex;
          if (
            index > 0 &&
            index < chartdata.length - 1 &&
            (value === minValue || value === maxValue)
          ) {
            return `${Math.round(value)}`;
          }
          return null;
        },
      },
    },
    scales: {
      x: {
        display: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
          font: {
            size: 10,
          },
        },
      },
      y: {
        display: false,
        grid: {
          display: true,
        },
        beginAtZero: false,
        min: Math.min(minValue, ...chartdata) - 30,
        max: Math.max(maxValue, ...chartdata) + 30,
      },
    },
  };

  return (
    <div>
      <div className="mb-1">
        {title && (
          <Paragraph
            text={title}
            props={{ fontSize: "10px", fontWeight: 500 }}
            ParagraphType="Paragraph22"
            color="textPrimary"
          />
        )}
      </div>
      <Line
        data={data}
        options={options}
        style={{ width: "100%", maxHeight: "140px", objectFit: "cover" }}
      />
    </div>
  );
};

export default LineChart;

import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import platform1 from "../../../assets/imgs/Platform_small_icon_1.svg";
import arrowRight from "../../../assets/imgs/arrow-right.svg";
import checkIcon from "../../../assets/imgs/check-icon.svg";

type FeaturePlatformCardProps = {
  PlatformSmallIcon: string;
  PlatformName: string;
  PlatformId: string;
  PlatformSync: number; 
};

const FeaturePlatformCard: React.FC<FeaturePlatformCardProps> = ({
  PlatformSmallIcon,
  PlatformName,
  PlatformId,
  PlatformSync,
}) => {
  return (
    <div
      className="p-3 pb-5 flex gap-4 items-center rounded-2xl relative cursor-pointer"
      style={{
        background: Colors.backgroundSecundary,
      }}
    >
      <div className="relative w-[60.52px] h-[47.84px] xl:h-[57.84px]">
        <div className="rounded-xl w-[53px] md:w-[58px] xl:w-[64px] 2xl:w-[71px] h-[53px] md:h-[58px] xl:h-[64px] 2xl:h-[71px] flex items-end overflow-hidden" >
          <img
            className={ PlatformSmallIcon ? " scale-[1]": "h-[45px]"}
            src={PlatformSmallIcon || platform1}
            alt=""
          />
        </div>
      </div>
      <div>
        <Paragraph
          text={PlatformName}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[18px] md:text-[20.527px] xl:text-[22.527px] 2xl:text-[29.527px] leading-[110%] font-medium"
        />

        {(PlatformSync >= 0) && 
          <Paragraph
          text={PlatformSync + " League synced"}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[12px] md:text-[13.5px] xl:text-[15.527px] 2xl:text-[22.527px] leading-[110%] font-medium"
        /> 
        }
      </div>
      <div className="ml-auto rounded-[100px] w-[36px] h-[36px] flex justify-center items-center" style={{
              background: Colors.backgroundSecundary,
            }}>
         <img src={arrowRight} alt=""  />
      </div>

    </div>
  );
};

export default FeaturePlatformCard;

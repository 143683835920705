import { isMobile } from "react-device-detect";
import HttpRequestChat from "../../Services/HttpRequestChat";

export const ChatRotoBot = async (
  initial_query: string,
  chat_id: string | undefined,
  user_email: string | null = null,
  playerId: string | null = null,
  leagueId: string | null = null,
  ownerId: string | null = null,
) => {
  try {
    if (playerId) {
      const response = await HttpRequestChat({
        method: "POST",
        url: "/v1/player-profile-chat",
        data: {
          initial_query: initial_query,
          user_email: user_email,
          chat_id: chat_id,
          roto_player_id: playerId,
        },
      });

      return {
        data: response.data,
        status: response.status,
      };
    } else {
      const response = await HttpRequestChat({
        method: "POST",
        url: "/v1/main-chat-endpoint",
        data: {
          initial_query: initial_query,
          user_email: user_email,
          chat_id: chat_id,
          league_id: leagueId,
          owner_id: ownerId,
        },
      });

      return {
        data: response.data,
        status: response.status,
      };
    }
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const fetchChat = async (chat_id: string) => {
  try {
    const response = await HttpRequestChat({
      method: "GET",
      url: "/chats/" + chat_id + "/",
    });
    // console.log(response.data);
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const ChatRotoBotSuggestions = async () => {
  try {
    const response = await HttpRequestChat({
      method: "POST",
      url: "/v1/main-chat-suggestions",
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const createNewChat = async (userId: string) => {
  try {
    const response = await HttpRequestChat({
      method: "POST",
      url: "/v1/create-chat",
      data: {
        user_email: userId,
      },
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const fetchDetailChat = async (chatId: string) => {
  try {
    const response = await HttpRequestChat({
      method: "GET",
      url: `/v1/detail_chats/${chatId}`,
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const fetchHistoryChatsByUser = async (userId: string) => {
  try {
    const response = await HttpRequestChat({
      method: "GET",
      url: `/v1/chats/${userId}?is_mobile=${isMobile}`,
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { enableYahooSync } from "../../../src/Controllers/LeagueSync";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";

const Oauth2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasRequestedRef = useRef(false);
  const { getUserData } = useSupabaseAuth();

  const fetchAccessToken = async (code: string) => {
    const userData = await getUserData();
    const userId = userData?.data?.user?.id;
    const response = await enableYahooSync(userId, code);
    navigate("/league-sync/YAHOO");
  };

  useEffect(() => {
    if (hasRequestedRef.current) {
      return;
    }

    try {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      const state = params.get("state");

      if (code) {
        console.log("Authorization code:", code);
        console.log("State:", state);
        fetchAccessToken(code);
      }
      hasRequestedRef.current = true;
    } catch (error) {
      console.error("Error processing the URL parameters:", error);
    }
  }, [location]);

  return (
    <div>
      <h1>Yahoo OAuth2</h1>
    </div>
  );
};

export default Oauth2;

import platform1 from "../.././assets/imgs/Platform_small_icon_1.svg";
import platform2 from "../.././assets/imgs/Platform_small_icon_2.svg";
import platform3 from "../.././assets/imgs/Platform_small_icon_3.svg";

export const TYPECHAT = {
    user: "USER",
    ai_question: "AI_QUESTION",
    ai_answer: "AI_ANSWER",
};

export const TABLE_ROUTES = [
    "general_research_weekly",
    "general_research_seasonal",
    "general_research_season_recap",
];
  
export const PLATFORMCARDS = [
    {
      id: 1,
      title: "ESPN Fantasy",
      icon: platform1,
      sync: -1,
      fantasy_type: "ESPN",
    },
    {
      id: 2,
      title: "Sleeper",
      icon: platform2,
      sync: -1,
      fantasy_type: "SLEEPER",
    },
    {
      id: 3,
      title: "Yahoo Fantasy",
      icon: platform3,
      sync: -1,
      fantasy_type: "YAHOO",
    },
  ]

import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import League1 from "../../../assets/imgs/league_small_icon_1.svg";
import checkIcon from "../../../assets/imgs/check-icon.svg";

type FeatureLeagueCardProps = {
  LeagueSmallIcon: string;
  LeagueName: string;
  LeagueId: string;
  LeagueSync: boolean;
};

const FeatureLeagueCard: React.FC<FeatureLeagueCardProps> = ({
  LeagueSmallIcon,
  LeagueName,
  LeagueId,
  LeagueSync,
}) => {
  console.log("LeagueSync", LeagueName);
  return (
    <div
      className="p-3 pb-5 flex gap-4 items-center rounded-2xl relative cursor-pointer"
      style={{
        background: Colors.backgroundSecundary,
      }}
    >
      <div className="relative w-[60.52px] h-[47.84px] xl:h-[57.84px]">
        <div className="rounded-xl w-[53px] md:w-[58px] xl:w-[64px] 2xl:w-[71px] h-[53px] md:h-[58px] xl:h-[64px] 2xl:h-[71px] flex items-end overflow-hidden">
          <img
            className={LeagueSmallIcon ? " scale-[1]" : "h-[45px]"}
            src={LeagueSmallIcon || League1}
            alt=""
          />
        </div>
      </div>
      <div>
        <Paragraph
          text={LeagueName}
          ParagraphType="Pragragh22"
          color="textPrimary"
          className="text-[18px] md:text-[20.527px] xl:text-[22.527px] 2xl:text-[29.527px] leading-[110%] font-medium"
        />
      </div>
      <div
        className="ml-auto rounded-[100px] w-[30px] h-[30px] flex justify-center items-center"
        style={{
          background: Colors.backgroundSecundary,
        }}
      >
        {LeagueSync && <img src={checkIcon} alt="" />}
      </div>
    </div>
  );
};

export default FeatureLeagueCard;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { Colors } from "../Commons/Colors/Colors";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "../../assets/imgs/arrow-back-left.svg";
import ChatBotIcon from "../../assets/imgs/new-chat-icon.svg";
import { Paragraph } from "../Commons/Paragraph";
import BarChart from "../Commons/BarCharts/BarCharts";
import TableCom from "../Commons/Tabel/Table";
import GenericTable from "../Commons/Tabel/GenericTable";
import { leagueTableData, leagueTableHeaders } from "./mockData";
import { teamColors } from "../../config/constants/teamColors";
import LeagueTeamSelect from "../Commons/LeagueTeamSelect/LeagueTeamSelect";
import { teamOptions } from "../LeagueHub/mockData";
import CircularProgressBar from "../Commons/CircularProgressBar/CircularProgressBar";

import {
  fetchMyleagues,
  fetchPlayerByUserAndLeague,
} from "../../Controllers/MyLeagueSync";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { set } from "date-fns";
import useUserLeagueSync from "../../hooks/useLeagueSync";
import WaveProgressBar from "./WaveProgressBar";

const LeagueHubMyTeam = () => {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(-1);
  const handleChatClick = () => navigate("/chat");
  const { useAuthStore } = useSupabaseAuth();
  const user = useAuthStore((state: any): any => state.user);
  const [myLeagues, setMyLeagues] = useState<any>([]);
  const [startersPlayers, setStartersPlayers] = useState<any>([]);
  const [benchsPlayers, setBenchsPlayers] = useState<any>([]);
  const [selectedLeague, setSelectedLeague] = useState<any>(null);
  const [teamGraphData, setTeamGraphData] = useState<any>([]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = useState(true);

  const barData = [
    { label: "QB", value: 300, color: "#E04B92" },
    { label: "RB", value: 400, color: "#0048FF" },
    { label: "WR", value: 100, color: "#9C49EF" },
    { label: "TE", value: 200, color: "#1BE8E8" },
  ];

  const colorPosition = [
    { label: "QB", color: "#E04B92" },
    { label: "RB", color: "#0048FF" },
    { label: "WR", color: "#9C49EF" },
    { label: "TE", color: "#1BE8E8" },
  ];

  const renderPlayerDetails = (element: any) => (
    <div className="flex items-center gap-3">
      <div
        className="rounded-xl w-[49.2px] h-[49.2px] flex items-end overflow-hidden "
        style={{
          backgroundColor: barData.find(
            (item) => item.label === element.position,
          )?.color
            ? barData.find((item) => item.label === element.position)?.color
            : "1BE8E8",
        }}
      >
        <img
          className={element.headshot_url ? "scale-[1.8]" : "h-[45px]"}
          src={element.headshot_url}
          alt=""
        />
      </div>
      <div>{element.player_name}</div>
    </div>
  );

  const leagueTableProperties = [
    "position",
    renderPlayerDetails,
    "player_trade_value",
    "posRank",
    "player_trade_value",
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollLeft = containerRef.current.scrollLeft;
        setScrollPosition(scrollLeft);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const fetchLueagueWithRetry = async () => {
    const user_id = user?.user?.id ? user?.user?.id : user?.id;
    setMyLeagues([]);
    let retries = true;
    let delay = 4000;

    while (retries) {
      if (user_id) {
        const response = await fetchMyleagues(user_id);

        if (response.status && response?.data?.data[0]?.team_grades) {
          setMyLeagues(
            response?.data?.data[0]?.leagues_array.filter(
              (item: any) => item.databrick_sync === true,
            ),
          );
          setStartersPlayers(
            response?.data?.data[0]?.leagues_array[0]?.starters_players,
          );
          setBenchsPlayers(
            response?.data?.data[0]?.leagues_array[0]?.benchs_players,
          );

          setTeamGraphData(response?.data?.data[0]?.team_grades);

          if (
            response?.data?.data[0]?.leagues_array[0]?.benchs_players.length > 0
          ) {
            setOpenModal(false);
            retries = false;
            return;
          }
        }
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
      delay *= 2;
    }
  };

  useEffect(() => {
    fetchLueagueWithRetry();
  }, []);

  const getColorByPosition = (position: string) => {
    const found = colorPosition.find((item) => item.label === position);
    return found ? found.color : colorPosition[0].color;
  };

  const graph = useMemo(() => {
    if (!teamGraphData) return [];

    return teamGraphData?.positional_grades?.positions.map(
      (item: any, index: any) => {
        return {
          label: item,
          value: teamGraphData?.positional_grades?.percentiles[index],
          color: getColorByPosition(item),
        };
      },
    );
  }, [teamGraphData]);

  console.log("graph", graph);

  const fetchPlayers = async (league_id: string | null | undefined) => {
    const user_id = user?.user?.id ? user?.user?.id : user?.id;

    if (user_id && league_id) {
      const response = await fetchPlayerByUserAndLeague(user_id, league_id);

      if (response.status) {
        setStartersPlayers(response?.data?.data?.starters_players);
        setBenchsPlayers(response?.data?.data?.benchs_players);
      }
    }
  };

  const handleSelectedLeague = (value: any) => {
    setSelectedLeague(value);
    fetchPlayers(value?.league_id);
  };

  return (
    <Layout>
      {openModal ? (
        <WaveProgressBar
          text="Syncing Your Team.."
          subText={
            "We'll send you a notification when\nyou're team has fully loaded"
          }
          goBack={handleBackClick}
        />
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <div
              className="cursor-pointer rounded-full w-[58px] h-[58px] flex justify-center items-center"
              style={{ background: Colors.backgroundSecundary }}
              onClick={handleBackClick}
            >
              <img src={ArrowBackIcon} alt="Back" />
            </div>
            <div className="relative min-w-[160px] min-h-[45px]">
              <div className=" absolute top-0 left-0 w-full">
                <LeagueTeamSelect
                  options={myLeagues.length > 0 ? myLeagues : []}
                  onSelect={(value: any) => handleSelectedLeague(value)}
                />
              </div>
            </div>
            <div
              className="cursor-pointer rounded-full w-[58px] h-[58px] flex justify-center items-center"
              onClick={handleChatClick}
            >
              <img src={ChatBotIcon} alt="Chat" />
            </div>
          </div>
          <div className="relative">
            <div
              ref={containerRef}
              className="flex overflow-auto gap-10 no-scrollbar mt-7"
              style={{ scrollSnapType: "x mandatory" }}
            >
              <div
                className="w-full flex-shrink-0"
                style={{ scrollSnapAlign: "start" }}
              >
                <div className="flex justify-between items-center gap-4">
                  <div>
                    <Paragraph
                      text="Your Team Rating"
                      className="text-[22px] text-white"
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                    />
                    <Paragraph
                      text="How you rank against others in your league"
                      props={{
                        fontSize: "12px",
                        fontWeight: 400,
                        opacity: "0.7",
                      }}
                      ParagraphType="Paragraph22"
                      color="textPrimary"
                    />
                  </div>
                  <CircularProgressBar
                    percentage={teamGraphData?.my_overall_team_grade}
                    pathColor={"#16C265"}
                  />
                </div>
                <div className="mt-4 mb-6">
                  <BarChart data={graph ? graph : []} />
                </div>
              </div>

              <div
                className="w-full flex-shrink-0"
                style={{ scrollSnapAlign: "start" }}
              >
                <div>
                  <Paragraph
                    text="Team Analysis Breakdown"
                    className="text-[22px] text-white"
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                  <Paragraph
                    text="Detailed insights on your team's performance"
                    props={{
                      fontSize: "12px",
                      fontWeight: 400,
                      opacity: "0.7",
                    }}
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                </div>
                <div className="mt-5">
                  <Paragraph
                    text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam laborum reiciendis sequi temporibus nobis id. Magnam adipisci, quidem recusandae veniam laborum id architecto. ."
                    className="text-[12px] text-white"
                    ParagraphType="Paragraph22"
                    color="textPrimary"
                  />
                  <Paragraph
                    text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam laborum reiciendis sequi temporibus nobis id. Magnam adipisci, quidem recusandae veniam laborum id architecto. Fugit totam voluptas aliquid quis, repellat maxime. Fugit totam voluptas aliquid quis, repellat maxime."
                    ParagraphType="Paragraph22"
                    className="text-[12px] text-white mt-3"
                    color="textPrimary"
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-2 justify-end gap-1 absolute bottom-2 left-1/2 -translate-x-1/2 z-40">
              <div
                className=" w-2 h-2 rounded-full"
                style={{
                  backgroundColor: "white",

                  opacity: scrollPosition <= 100 ? 1 : 0.5,
                }}
              ></div>
              <div
                className=" w-2 h-2 rounded-full"
                style={{
                  backgroundColor: "white",

                  opacity: scrollPosition >= 100 ? 1 : 0.5,
                }}
              ></div>
            </div>
          </div>

          <div className="mt-3">
            <Paragraph
              text="Starters"
              props={{
                fontSize: "14px",
                fontWeight: 700,
                textDecoration: "underline",
              }}
              ParagraphType="Paragraph22"
              color="textPrimary"
            />
          </div>
          <div className="flex-1 pb-5">
            <GenericTable
              headings={leagueTableHeaders}
              data={startersPlayers}
              // data={leagueTableData}
              column={leagueTableProperties}
            />
          </div>

          <div className="mt-3">
            <Paragraph
              text="Bench"
              props={{
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline",
              }}
              ParagraphType="Paragraph22"
              color="textPrimary"
            />
          </div>
          <div className="flex-1 pb-5">
            <GenericTable
              headings={leagueTableHeaders}
              data={benchsPlayers.length > 0 ? benchsPlayers : []}
              // data={leagueTableData}
              column={leagueTableProperties}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default LeagueHubMyTeam;

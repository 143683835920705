import { useEffect, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { getROSRankings } from "../../Controllers/PlayerRanking";

import WaveProgressBar from "./WaveProgressBar/WaveProgressBar";
import TradeReport from "./TradeReport/TradeReport";
import { getTradeAnalyzer } from "../../Controllers/TradeAnalyzer/TradeAnalyzer";
import { DataItem } from "../../Controllers/TradeAnalyzer/type";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";
import { motion, AnimatePresence } from "framer-motion";
import SelectPlayers from "../Commons/SelectPlayers/SelectPlayers";
import { isMobile } from "react-device-detect";
import { useApplePaymentAuth } from "../../Context/AppleContext/AppleContext";
import OutOfCredits from "../Commons/OutOfCredits/OutOfCredits";

type PositionItem = {
  id: number;
  name: string;
};

const TradeAnalyzer = () => {
  const navigate = useNavigate();
  const { logEventAnalytics } = useFirebaseAnalytics();

  const [playersData, setPlayersData] = useState<DataItem[]>([]);
  const [tradeScreen, setTradeScreen] = useState<string>("TradeAway");
  const [creditRemaing, setCreditRemaing] = useState<number>(3);

  const [playersDataTradeAway, setPlayersDataTradeAway] = useState<DataItem[]>(
    [],
  );
  const [playersDataTradeFor, setPlayersDataTradeFor] = useState<DataItem[]>(
    [],
  );

  const [positionSelected, setPosicionSelected] = useState<PositionItem>({
    id: 1,
    name: "All",
  });
  const [searchTerm, setSearchTerm] = useState<string | null | undefined>();
  const [report, setReport] = useState<string | null | undefined>("");
  const [tradeOutcome, setTradeOutcome] = useState<string | null | undefined>(
    "",
  );
  const { getUserData, getProfileForDetails } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const { platform } = useApplePaymentAuth();

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }

    getCreditRemaing(response?.data?.user?.id);
  };

  const getCreditRemaing = async (user_id: any) =>{
    const profile = await getProfileForDetails(user_id);
    
    setCreditRemaing(profile.feature_credits);

    if(profile.feature_credits == 0 && !profile.is_super_user ){
      if(!profile.isActiveSubscription){
        setTradeScreen("OutOfCredits");
      }      
    }
  }

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchaPlayerData = async () => {
    const rosRankings = await getROSRankings();
    if (!rosRankings?.data) return;
    let filteredPlayers = rosRankings?.data;

    filteredPlayers = filteredPlayers
      .filter(
        (player: DataItem) =>
          !playersDataTradeAway.some(
            (tradedPlayer) =>
              tradedPlayer.roto_player_id === player.roto_player_id,
          ),
      )
      .filter(
        (player: DataItem) =>
          !playersDataTradeFor.some(
            (tradedPlayer) =>
              tradedPlayer.roto_player_id === player.roto_player_id,
          ),
      );

    if (searchTerm) {
      filteredPlayers = filteredPlayers.filter(
        (player: any) =>
          player.playerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (positionSelected.name === "All" ||
            player.playerPosition === positionSelected.name),
      );
    } else if (positionSelected.name !== "All") {
      filteredPlayers = filteredPlayers.filter(
        (player: any) => player.playerPosition === positionSelected.name,
      );
    }

    setPlayersData(filteredPlayers);
  };

  useEffect(() => {
    fetchaPlayerData();
  }, [positionSelected, searchTerm, playersDataTradeAway, playersDataTradeFor]);

  const handleSelectPosition = (item: PositionItem) => {
    setPosicionSelected(item);
  };

  const handleFilterSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSelectPlayer = (newPlayer: DataItem) => {
    setSearchTerm("");
    setPosicionSelected({
      id: 1,
      name: "All",
    });
    if (tradeScreen === "TradeAway") {
      logEventAnalytics("trade_player_selected_away", {
        name: "Player Selected Trade Away",
        playerId: newPlayer.roto_player_id,
      });
      setPlayersDataTradeAway((prevPlayers) => [...prevPlayers, newPlayer]);
    } else {
      logEventAnalytics("trade_player_selected_for", {
        name: "Player Selected Trade For",
        playerId: newPlayer.roto_player_id,
      });
      setPlayersDataTradeFor((prevPlayers) => [...prevPlayers, newPlayer]);
    }
    
  };

  const removePlayerTradeAway = (idToRemove: string | undefined) => {
    setPlayersDataTradeAway((prevPlayers) =>
      prevPlayers.filter((player) => player.roto_player_id !== idToRemove),
    );
  };

  const removePlayerTradeFor = (idToRemove: string | undefined) => {
    setPlayersDataTradeFor((prevPlayers) =>
      prevPlayers.filter((player) => player.roto_player_id !== idToRemove),
    );
  };

  const handleButtonNext = () => {
    logEventAnalytics("trade_nav_next", {
      name: "Trade Analyzer Next",
    });
    setTradeScreen("TradeFor");
  };

  const handleButtonBack = () => {
    if (tradeScreen === "TradeAway") {
      navigate("/home");
    } else {
      setTradeScreen("TradeAway");
    }
  };

  const handleButtonCalculate = async () => {
    logEventAnalytics("trade_calculate", {
      name: "Trade Analyzer Trade Analyzed",
    });
    //calculate
    const userId = currentUser?.id;
    setTradeScreen("Calculating");
    const tradeData = await getTradeAnalyzer(
      playersDataTradeAway,
      playersDataTradeFor,
      userId,
      platform
    );

    switch (tradeData.status) {
      case 200:
        setTradeScreen("Report");
        setReport(tradeData.data?.detail_chat?.ai_response);
        setTradeOutcome(tradeData.data?.trade_outcome);
        break;
      case 402:
        if (isMobile && platform && platform === "ios") {
          navigate("/chatbot-paywall");
        } else {
          navigate("/home");
        }
        break;
      default:
        setTradeScreen("Report");
        setReport("Error has occurred, please try again");
        setTradeOutcome("");
        break;
    }

  };

  const handleOnReset = () => {
    logEventAnalytics("trade_complete_again", {
      name: " Trade Analyzer Complete New Query",
    });
    setPlayersDataTradeAway([]);
    setPlayersDataTradeFor([]);
    setSearchTerm("");
    setTradeScreen("TradeAway");

    const userId = currentUser?.id;
    getCreditRemaing(userId);
  };

  return (
    <>
      <Layout>
        {tradeScreen === "TradeAway" && (
          <SelectPlayers
            title="Trading Away"
            subTitle="Choose the players to trade away."
            selectedPlayersData={playersDataTradeAway}
            playersData={playersData}
            handleButtonBack={handleButtonBack}
            removePlayer={removePlayerTradeAway}
            handleFilterSearch={handleFilterSearch}
            handleSelectPosition={handleSelectPosition}
            handleSelectPlayer={handleSelectPlayer}
            handleButtonNext={handleButtonNext}
            search={searchTerm || ""}
            minPlayers={1}
            maxPlayers={3}
            textButton="Next"
            credit={`Credits Remaining: ${creditRemaing}`}
            InicialselectedTab={positionSelected}
          ></SelectPlayers>
        )}
        {tradeScreen === "TradeFor" && (
          <SelectPlayers
            title="Trading For"
            subTitle="Choose the players to trade for."
            selectedPlayersData={playersDataTradeFor}
            playersData={playersData}
            handleButtonBack={handleButtonBack}
            removePlayer={removePlayerTradeFor}
            handleFilterSearch={handleFilterSearch}
            handleSelectPosition={handleSelectPosition}
            handleSelectPlayer={handleSelectPlayer}
            handleButtonNext={handleButtonCalculate}
            search={searchTerm || ""}
            minPlayers={1}
            maxPlayers={3}
            textButton="Calculate Now"
            InicialselectedTab={positionSelected}
          ></SelectPlayers>
        )}
        {tradeScreen === "Calculating" && <WaveProgressBar />}
        {tradeScreen === "OutOfCredits" && <OutOfCredits />}
      </Layout>
      <AnimatePresence>
        {tradeScreen === "Report" && (
          <motion.div
            className="fixed top-0 right-0 w-full z-30 h-screen overflow-y-auto capabilities-bg"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.7, ease: "easeInOut" }}
          >
            <TradeReport
              playersDataTradeAway={playersDataTradeAway}
              playersDataTradeFor={playersDataTradeFor}
              report={report}
              tradeOutcome={tradeOutcome}
              onReset={handleOnReset}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default TradeAnalyzer;

import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar: React.FC<{
  percentage: number;
  pathColor: string;
  className?: string;
}> = ({ percentage, pathColor, className = "w-20 h-20" }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(parseInt(`${percentage}`, 10));
  }, [percentage]);

  return (
    <div className={` ${className}`}>
      <CircularProgressbarWithChildren
        value={progress}
        counterClockwise = {true}
        styles={buildStyles({
          pathColor: pathColor,
          trailColor: "transparent",
        })}
      >
        <div
          style={{
            fontSize: "27.342px",
            fill: "white",
            textAlign: "center",
            fontWeight: "bold",
            position: "relative",
            color: "white"
          }}
        >
          {Number.isNaN(progress) ? "--" : progress}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgressBar;

import { useEffect, useState } from "react";
import { Paragraph } from "../Paragraph";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { mapWithFallback } from "../../../lib/utils";

const TypingParaAnimation: React.FC<{
  strings: string[];
  typingSpeed?: number;
}> = ({ strings, typingSpeed = 100 }) => {
  const [displayedStrings, setDisplayedStrings] = useState<string[]>([]);
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [animationComplete, setAnimationComplete] = useState(false);

  const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
  };

  useEffect(() => {
    let typingInterval: NodeJS.Timeout;

    if (!animationComplete) {
      const stringResponse = strings[currentStringIndex];
      if (currentText.length < strings[currentStringIndex].length) {
        typingInterval = setTimeout(() => {
          const nextChar = stringResponse[currentText.length];
          setCurrentText((prev) => prev + nextChar);

          if (
            nextChar === " " ||
            currentText.length === stringResponse.length - 1
          ) {
            hapticsImpactLight();
          }
        }, typingSpeed);
      } else {
        typingInterval = setTimeout(() => {
          if (!displayedStrings.includes(strings[currentStringIndex])) {
            setDisplayedStrings((prev) => [
              ...prev,
              strings[currentStringIndex],
            ]);
          }

          setCurrentText("");
          setCurrentStringIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % strings.length;
            if (nextIndex === 0) {
              setAnimationComplete(true);
            }
            return nextIndex;
          });
        }, 10);
      }
    }

    return () => clearTimeout(typingInterval);
  }, [
    currentText,
    currentStringIndex,
    strings,
    typingSpeed,
    displayedStrings,
    animationComplete,
  ]);

  return (
    <div className="flex flex-col gap-4">
      {mapWithFallback(displayedStrings).map((text, index) => (
        <Paragraph
          text={text || ""}
          props={{ fontSize: "14px", fontWeight: 400, opacity: "0.7" }}
          ParagraphType="Paragraph22"
          color="textPrimary"
          key={index}
        />
      ))}
      {currentText && (
        <Paragraph
          text={currentText || ""}
          props={{ fontSize: "14px", fontWeight: 400, opacity: "0.7" }}
          ParagraphType="Paragraph22"
          color="textPrimary"
        />
      )}
    </div>
  );
};

export default TypingParaAnimation;

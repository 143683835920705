import React, { useEffect, useState } from "react";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import User_details_img from "../../assets/imgs/LoginBannerImage.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import UserDetailsCard from "./UserDetailsCard/UserDetailsCard";
import { useSupabaseAuth } from "../../Context/AuthContext/AuthContext";
import { convertDateFormat, hapticsImpactLight, validateEmail } from "../../lib/utils";
import warning_icon from "../../assets/imgs/warning-icon.svg";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";

export const UserDetails: React.FC = () => {
  const navigate = useNavigate();
  const { logEventAnalytics } =useFirebaseAnalytics();
  const { useAuthStore, getProfileForDetails, signupProfile } =
    useSupabaseAuth();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [showError, setShowError] = useState(false);
  const user = useAuthStore((state: any): any => state.user);
  const appleProfile = useAuthStore((state: any): any => state.appleProfile);
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user_id = user?.user?.id ? user?.user?.id : user?.id;

      if (user_id) {
        if (user?.user?.app_metadata?.provider === "apple" && appleProfile) {
          setEmail(user?.user?.email || appleProfile.emailApple || "");
          setFirstName(appleProfile?.givenName);
          setLastName(appleProfile?.familyName);
        }

        if (user?.user?.app_metadata?.provider === "google") {
          setEmail(user?.user?.email || "");
          setFirstName(user?.user?.user_metadata?.full_name || "");
        }

        const profile = await getProfileForDetails(user_id);

        if (profile) {
          if (profile.user_email) {
            setEmail(profile.user_email || "");
          }

          if (profile.first_name) {
            setFirstName(profile.first_name || "");
          }

          if (profile.last_name) {
            setLastName(profile.last_name || "");
          }

          if (!profile.first_name && profile.user_name) {
            setFirstName(profile.user_name || "");
          }

          if (profile.referral_code) {
            setReferralCode(profile.referral_code || "");
          }
        }
      }
    };

    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const handleChange = (field: string, value: string) => {
    if (field === "email") {
      setEmail(value);
    } else if (field === "first name") {
      setFirstName(value);
    } else if (field === "last name") {
      setLastName(value);
    } else if (field === "referral code") {
      setReferralCode(value);
    }
  };

  const handleSubmit = async () => {
    const user_id = user?.user?.id ? user?.user?.id : user?.id;
    logEventAnalytics("user_details_complete", {
      name: "User Details Complete",
    });

    if (user && user_id && !loading) {
      if (firstName.length === 0 || lastName.length === 0 || !validateEmail(email)) {
        setShowError(true);
        return;
      }
      setLoading(true);
      try {
        const response = await signupProfile(
          user_id as string,
          firstName,
          lastName,
          email,
          referralCode,
        );

        if (response?.sucess) {
          useAuthStore.getState().clearAppleProfile();
          navigate("/home");
        } else {
          setServerError(true);
        }
      } catch (error) {
        setLoading(false);
        setServerError(true);
      }

      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="flex items-center justify-between relative z-20">
        <div
          className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center grayBtn"
          onClick={() => {
            logEventAnalytics("user_details_back", {
              name: "User Details Back",
            });
            hapticsImpactLight();
            navigate("/welcome")}}
        >
          <img src={ArrowBackIcon} alt="" />
        </div>
        <Paragraph
          text="User Details"
          props={{ fontSize: "22px", marginLeft: "-45px" }}
          ParagraphType="Pragragh22"
          color="textPrimary"
        />
        <div></div>
      </div>
      <div className=" flex h-[calc(90svh_-_80px)] flex-col justify-between items-center sm:flex-row gap-6">
        <div className="w-full sm:w-1/2 flex-1 sm:flex-none">
          <div className="flex flex-col text-center justify-center items-center h-full">
            <div className=" relative h-full flex justify-center items-center w-full">
              <img
                src={User_details_img}
                alt=""
                className="h-[90%] max-h-[280px] sm:max-h-full lg:max-h-full lg:min-w-[330.25px] md:min-w-[270px] sm:min-w-[260px] min-w-[260px] absolute z-10 top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%]"
              />
              <div className="h-[90%] max-h-[250px] sm:h-[40vh] sm:max-h-[270px] w-[40%] sm:w-[150.474px] chatbot-bot-bg md:blur-[95px]"></div>
            </div>
            <div className="lg:text-[45px] md:text-[36px] sm:text-[30px] text-[27px] text-white font-bold mt-5 md:mt-10 leading-[110%] filter">
              Let’s get to know you
            </div>
            <div
              className="text-[14px] sm:text-2xl md:*::w-2/3 mt-1 sm:mt-3"
              style={{
                color: Colors.textLigth,
              }}
            >
              We need a few details to set up your profile and get you started.
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 md:w-2/3 sm:mt-0 flex flex-col gap-5 justify-center text-center w-full">
          <UserDetailsCard
            email={email}
            firstName={firstName}
            lastName={lastName}
            referralCode={referralCode}
            showError={showError}
            onChange={handleChange}
          />
          {serverError && (
            <div className="flex w-full justify-center">
              <Paragraph
                text={"Invalid server error"}
                props={{
                  fontSize: "15px",
                  fontWeight: 600,
                  color: Colors.textRed,
                }}
                ParagraphType="Paragraph22"
                color="textPrimary"
              />
              <img src={warning_icon} alt="" className=" ml-3" />
            </div>
          )}

          <Button
            className="w-full h-[60px] text-base mb-4"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
};
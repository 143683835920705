import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import SleeperModalImg from "../../../assets/imgs/sleeper-modal-img.svg";

import { enableSleeperSync } from "../../../Controllers/LeagueSync";
import { useSupabaseAuth } from "../../../../src/Context/AuthContext/AuthContext";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

interface Props {
  setOpen: (isOpen: boolean) => void;
  showLeagues: () => void;
  open: boolean;
  openModalSync: boolean;
  setOpenModalSync: (isOpen: boolean) => void;
  errorSync: string | null;
  setErrorSync: (error: string | null) => void;
  sleeperUserName: string;
  setSleeperUserName: (username: string) => void;
  disabledButton: boolean;
  setdisabledButton: (disabled: boolean) => void;
}
const SleeperModal = ({
  setOpen,
  showLeagues,
  open,
  openModalSync,
  setOpenModalSync,
  errorSync,
  setErrorSync,
  sleeperUserName,
  setSleeperUserName,
  disabledButton,
  setdisabledButton,
}: Props) => {
  // const [sleeperLeagueId, setSleeperLeagueId] = useState("");
  // const [sleeperUserId, setSleeperUserId] = useState("");
  const { getUserData } = useSupabaseAuth();
  const [currentUser, setCurrentUser] = useState<any>(null);
  const navigate = useNavigate();

  const fetchCurrentUser = async () => {
    const response = await getUserData();

    if (response) {
      setCurrentUser(response?.data?.user);
    }
  };

  const validaForm = async () => {
    if (sleeperUserName) {
      setdisabledButton(false);
    } else {
      setdisabledButton(true);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleSetSleeperSync = async () => {
    setOpenModalSync(true);
    const response = await enableSleeperSync(
      currentUser?.id,
      sleeperUserName,
      null,
      null,
      null,
      null,
      null,
    );

    if (response.status) {
      showLeagues();
      console.log(response);
      setErrorSync(null);
      navigate("/congratulations");
    } else {
      setErrorSync("Invalid username, try again");
    }
    setOpenModalSync(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="fixed top-0 right-0 w-full h-screen z-30"
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
          transition={{ duration: 0.7, ease: "easeInOut" }}
          style={{
            background: "rgba(24, 26, 32, 0.50)",
            backdropFilter: "blur(9px)",
          }}
        >
          <motion.div
            className="text-white flex justify-center items-center h-full p-[14px]"
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0.1 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          >
            <div
              style={{
                borderRadius: "20px",
                background: "#212130",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <img
                src={SleeperModalImg}
                alt="CapabilitiesModal Background"
                className="rounded-t-[20px] w-full"
              />

              <div className="mt-5 text-center p-2">
                <Paragraph
                  text="Sync With Sleeper"
                  props={{
                    fontSize: "23px",
                    fontWeight: "600",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                />
                <Paragraph
                  text="Enter your Sleeper username to link your league with RotoBot for personalized insights."
                  props={{
                    fontSize: "14px",
                    fontWeight: "400",
                    opacity: "0.50",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    marginTop: "10px",
                  }}
                  ParagraphType="Paragraph22"
                  color="textPrimary"
                />
                {errorSync && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "left",
                    }}
                  >
                    <Paragraph
                      text={errorSync}
                      props={{
                        fontSize: "14px",
                        fontWeight: "400",
                        // opacity: "0.50",
                        paddingLeft: "23px",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                      ParagraphType="Paragraph22"
                      color="default"
                    />
                  </div>
                )}
                <input
                  type="text"
                  className={`${!errorSync ? " mt-10" : ""} bg-[${Colors.backgroundSecundary}] p-[14px_10px] gap-[10px] border-0 rounded-[10px] border text-white placeholder:text-[${Colors.backgroundSecundary}]`}
                  onChange={(event) => setSleeperUserName(event.target.value)}
                  placeholder="Enter Username"
                  onBlur={(event) => validaForm()}
                  style={{ width: "90%" }}
                  value={sleeperUserName}
                />

                <Button
                  className=" w-[90%] rounded-[10px] mt-6 text-[16px] font-semibold mt-10"
                  disabled={disabledButton}
                  onClick={handleSetSleeperSync}
                >
                  Sync Now
                </Button>
                <div className="flex flex-col items-center mt-2 mb-5">
                  <Button
                    variant="destructive"
                    size="default"
                    onClick={(event) => setOpen(false)}
                    className="bg-transparent hover:bg-transparent text-[15px] font-semibold"
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SleeperModal;

import HttpRequestLeagueSync from "../../Services/HttpRequestLeagueSync";

export const fetchMyleagues = async (user_id: string) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/v1/databricks_syncs/users/leagues`,
      data: {
        user_id,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const fetchPlayerByUserAndLeague = async (
  user_id: string,
  league_id: string,
) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "POST",
      url: `/v1/databricks_syncs/players`,
      data: {
        user_id,
        league_id,
      },
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

export const fetchLeagueSyncByUser = async (userId: string) => {
  try {
    const response = await HttpRequestLeagueSync({
      method: "GET",
      url: `/v1/databricks_syncs/users/${userId}/has_sync`,
    });

    return {
      data: response,
      status: true,
    };
  } catch (error) {
    return {
      status: false,
      errors: error,
    };
  }
};

import React from "react";
import productionIcon from "../../../assets/imgs/production.svg";
import statEfficiency from "../../../assets/imgs/stats-efficiency.svg";
import statConsistency from "../../../assets/imgs/stats-consistency.svg";
import { mapWithFallback } from "../../../lib/utils";

interface StatItemProps {
  label: string;
  value: string | number;
  leagueAvg: string | number;
  rank: string;
  percentile: string | number;
  avgPercentileValues: string | number;
}

interface StatsSectionProps {
  categoryTitle: string;
  statTitles: string[];
  statValues: (string | number)[];
  percentileValues?: string[];
  avgValues?: (string | number)[];
  rankValues?: string[];
  avgPercentileValues?: (string | number)[];
}

const StatItem: React.FC<StatItemProps> = ({
  label,
  value,
  leagueAvg,
  rank,
  percentile,
  avgPercentileValues,
}) => {
  const percentage = Number(percentile) || 0;
  const leagueAvgPercentile = Number(avgPercentileValues) || 0;

  const formatNumber = (value: string | number): string => {
    if (typeof value === "number") {
      return value % 1 === 0 ? value.toString() : value.toFixed(2);
    }

    if (value.endsWith("%")) {
      return value;
    }

    const numValue = parseFloat(value);
    return !isNaN(numValue)
      ? numValue % 1 === 0
        ? numValue.toString()
        : numValue.toFixed(2)
      : "0";
  };

  return (
    <div className="mb-7">
      <div className="flex justify-between items-center text-xs md:text-xs xl:text-sm mb-1 text-[#9D9D9D]">
        <span>{label}</span>
        <span className="text-[#CACACA]">{rank}</span>
      </div>
      <div
        className="relative h-[27.567px] md:h-[42.567px] rounded-2xl"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          className="absolute -top-[5px] left-[50%] -translate-x-[40%] rounded-[15px] opacity-20 bg-[#DADADA] w-[3px] h-[37px] md:h-[52px] z-10"
          style={{
            left: `${leagueAvgPercentile}%`,
          }}
        ></div>
        <div
          className="absolute bottom-[-20px] left-[50%] -translate-x-[50%] text-[10px] md:text-xs text-[#9A9A9A]"
          style={{
            left: `${leagueAvgPercentile}%`,
          }}
        >
          {leagueAvg}
        </div>
        <div
          className="absolute top-0 left-0 h-full rounded-[8px] flex items-center"
          style={{
            width: `${percentage}%`,
            background: "linear-gradient(90deg, #012B99, #0148FE)",
          }}
        >
          <span className="text-[15px] font-semibold ml-[10px] text-[#CACACA]">
            {formatNumber(value)}
            {/* {value} */}
          </span>
        </div>
      </div>
    </div>
  );
};

const StatsSection: React.FC<StatsSectionProps> = ({
  categoryTitle,
  statTitles,
  statValues,
  percentileValues,
  avgValues,
  rankValues,
  avgPercentileValues,
}) => {
  const statsIcons = (categoryTitle: string) => {
    switch (categoryTitle) {
      case "Production":
        return (
          <img src={productionIcon} alt="productionIcon" className="w-6" />
        );
      case "Efficiency":
        return (
          <img src={statEfficiency} alt="statEfficiency" className="w-6" />
        );
      default:
        return (
          <img src={statConsistency} alt="statConsistency" className="w-6" />
        );
    }
  };

  return (
    <div className="mb-6">
      <div className="flex items-center text-white mb-4 sm:mb-5 md:mb-6 lg:mb-7">
        {statsIcons(categoryTitle)}
        <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-[45.393px] font-semibold ml-2">
          {categoryTitle}
        </h3>
      </div>
      {mapWithFallback(statTitles).map((title, index) => (
        <StatItem
          key={index}
          label={title}
          value={statValues[index] || "N/A"}
          leagueAvg={avgValues ? avgValues[index] : "10"}
          rank={rankValues ? rankValues[index] : "17"}
          percentile={(percentileValues && percentileValues[index]) || "0"}
          avgPercentileValues={
            (avgPercentileValues && avgPercentileValues[index]) || "0"
          }
        />
      ))}
    </div>
  );
};

export default StatsSection;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

interface BarChartData {
  label: string;
  value: number;
  color: string;
}

interface BarChartComponentProps {
  data: BarChartData[];
}

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data }) => {
  const chartData = {
    labels: data.map(item => item.label),
    datasets: [
      {
        label: 'Values',
        data: data.map(item => item.value),
        backgroundColor: data.map(item => item.color),
        borderColor: data.map(item => item.color),
        borderRadius: 9.6,
        borderSkipped: false,
        fontColor:"white"      
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      x: {
        display: true, 
        ticks: {
          color: "white",
          font: {
            size: 16,
          },
          stepSize: 1,
          beginAtZero: true
        }
      },
      y: {
        display: false, 
        beginAtZero: true,
      },
      
    },
    plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: "white",
          anchor: "end",
          offset: -30,
          align: "start",
          font: {
            size: 12,
          },
        }
      },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChartComponent;

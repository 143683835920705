import React from "react";
import { Colors } from "../../Commons/Colors/Colors";
import { Paragraph } from "../../Commons/Paragraph";
import { dataToday } from "./data";
import useFirebaseAnalytics from "../../../hooks/useFirabaseAnality";
import { useNavigate } from "react-router-dom";
import { mapWithFallback } from "../../../lib/utils";

type Chat = {
  created_at: string;
  id: number;
  detail: string | null;
  user_email: string;
  title: string;
};

const ChatGroup = ({
  groupTitle,
  chats,
}: {
  groupTitle: string;
  chats: Chat[];
}) => {
  const navigate = useNavigate();
  const { logEventAnalytics } = useFirebaseAnalytics();

  const object = {
    seleted: false,
  };

  const handleChatHistory = (chatId: string | null | undefined) => {
    logEventAnalytics("chat_open_history", {
      name: "Chat Open History",
    });

    navigate("/chat/" + chatId);
  };

  return (
    <div
      id="today-chats"
      className="mt-5 mx-5"
      style={{
        color: Colors.borderLigth,
        borderTop: "1px solid",
      }}
    >
      <Paragraph
        text={groupTitle}
        props={{
          fontSize: "12px",
          marginLeft: "10px",
          marginTop: "20px",
        }}
        ParagraphType="PragraghSemiBold400Normal"
        color="textLigth"
      />
      <div className="mt-2.5">
        <ul className="space-y-1">
          {mapWithFallback(chats).map((chat) => (
            <li
              className="p-2.5"
              key={chat.id}
              style={{
                background: object.seleted ? Colors.backgroundSecundary : "",
                borderRadius: object.seleted ? "6px" : "",
                border: object.seleted
                  ? "1px solid rgba(255, 255, 255, 0.15)"
                  : "",
              }}
            >
              <div onClick={() => handleChatHistory(chat?.id.toString())}>
                <Paragraph
                  text={chat.title}
                  props={{ fontSize: "10px" }}
                  ParagraphType="Pragragh22"
                  color="textPrimary"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const MenuItem = ({ chatHistories }: { chatHistories: any }) => {
  return (
    <div>
      {Object.entries(chatHistories).map(([groupTitle, chatList]) => {
        const chats = chatList as Chat[];
        if (chats.length > 0) {
          return (
            <ChatGroup key={groupTitle} groupTitle={groupTitle} chats={chats} />
          );
        }
        return null;
      })}
    </div>
  );
};

export default MenuItem;

import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "../.././assets/imgs/arrow-back-left.svg";
import onBoarding1 from "../.././assets/imgs/on-boarding-1.svg";
import onBoarding2 from "../.././assets/imgs/on-boarding-2.svg";
import onBoarding3 from "../.././assets/imgs/on-boarding-3.svg";
import { Colors } from "../Commons/Colors/Colors";
import { Paragraph } from "../Commons/Paragraph";
import { Layout } from "../Layouts/Layout";
import { Button } from "../ui/button";
import LottieAnimation from "../Commons/LottieAnimation/LottieAnimation";
import onBoardingAnimation1 from "../../assets/animations/on-boarding-animation-1.json";
import onBoardingAnimation2 from "../../assets/animations/on-boarding-animation-2.json";
import onBoardingAnimation3 from "../../assets/animations/on-boarding-animation-3.json";
import { AnimatePresence, motion } from "framer-motion";
import useFirebaseAnalytics from "../../hooks/useFirabaseAnality";
import { hapticsImpactLight, mapWithFallback } from "../../lib/utils";

export const OnBoarding = () => {
  const { logEventAnalytics } = useFirebaseAnalytics();
  const navigate = useNavigate();
  const location = useLocation();
  const sliderData = [
    {
      id: "0",
      title: "Meet Rotobot AI",
      description:
        "Your 24/7 AI Fantasy Guru. Fully equipped to tackle all your fantasy football needs.",
      animation: onBoardingAnimation1,
    },
    {
      id: "1",
      title: "Tap Into Insights",
      description:
        "AI-Powered Data Discovery to make your fantasy decisions a breeze.",
      animation: onBoardingAnimation2,
    },
    {
      id: "2",
      title: "Victory Awaits",
      description: "Experience the easiest way to elevate your fantasy game.",
      animation: onBoardingAnimation3,
    },
  ];

  const [currentTab, setCurrentTab] = useState<number>(0);

  useEffect(() => {
    if (location.state?.fromLogin) {
      setCurrentTab(2);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state, navigate, location.pathname]);

  const handleContinue = () => {
    if (currentTab < sliderData.length - 1) {
      logEventAnalytics("onboarding_next", {
        name: "OnBoadring Next Click",
      });
      setCurrentTab(currentTab + 1);
    } else {
      navigate("/welcome");
    }
  };

  const handleBack = () => {
    hapticsImpactLight();
    if (currentTab > 0) {
      logEventAnalytics("onboarding_next", {
        name: "OnBoadring Next Click",
      });
      setCurrentTab(currentTab - 1);
    } else {
      navigate("/");
    }
  };

  const Dots = ["", "", ""];

  return (
    <Layout>
      <div className="flex flex-col h-full justify-between overflow-y-auto no-scrollbar">
        <div
          className={`flex items-center justify-between w-full overflow-hidden shrink-0 h-[58px]`}
        >
          <motion.div
            className="cursor-pointer rounded-[100px] w-[58px] h-[58px] flex justify-center items-center transition-all duration-500 ease-in-out grayBtn"
            style={{
              opacity: currentTab === 0 ? 0 : 1,
            }}
            onClick={handleBack}
          >
            <img src={ArrowBackIcon} alt="" />
          </motion.div>

          <div className="cursor-pointer" onClick={() => {
            hapticsImpactLight();
            logEventAnalytics("onboarding_skip", {
              name: "OnBoadring Skip Click",
            });
            navigate("/welcome")
          }}>
            <Paragraph
              text="Skip"
              props={{ fontSize: "20px" }}
              ParagraphType="Pragragh22"
              color="textPrimary"
            />
          </div>
        </div>
        <div className="flex flex-col items-center w-full flex-1 pt-5">
          <div className="flex flex-col items-center">
            <div className="flex items-center gap-3 mb-5 relative w-[210px]">
              {Dots.map((_, index) => {
                return (
                  <div
                    className={` w-14 h-2 rounded-md transition-all duration-300`}
                    key={index}
                    style={{
                      backgroundColor:
                        currentTab === index
                          ? Colors.backgroundTertiary
                          : Colors.backgroundGrayPrimary,
                    }}
                  ></div>
                );
              })}
            </div>
            <div className="text-center min-h-[129px]">
              <div className="text-4xl font-bold text-white leading-[125%]">
                {sliderData[currentTab].title}
              </div>
              <div
                className=" mt-3 m-auto"
                style={{
                  color: Colors.textLigth,
                }}
              >
                {sliderData[currentTab].description}
              </div>
            </div>
          </div>
          <div className="flex-1 flex items-center">
            <AnimatePresence>
              <motion.div
                className="h-[90%]"
                initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.1 }}
                transition={{ duration: 0.7, ease: "easeInOut" }}
              >
                <LottieAnimation
                  animationData={sliderData[currentTab].animation}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <Button className="w-full h-[60px] text-base" onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </Layout>
  );
};

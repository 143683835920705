import React, { useEffect, useState } from "react";
import CircularProgressBar from "../../Commons/CircularProgressBar/CircularProgressBar";
import { Paragraph } from "../../Commons/Paragraph";
import { Colors } from "../../Commons/Colors/Colors";
import StatsSection from "../StatItem/StatItem";
import PerfomanceScoreTrand from "../PerfomanceScoreTrand/PerfomanceScoreTrand";
import { mapWithFallback } from "../../../lib/utils";

interface Props {
  player: any;
  handlePerfomanceModal?: any;
}

const PlayerScores = ({ player, handlePerfomanceModal }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCard, setSelectedCard] = useState("Performance");
  const [selectedCardData, setSelectedCarddata] = useState([] as any);

  useEffect(() => {
    if (player?.playerPerformanceScores?.performanceCards) {
      const filterData =
        player?.playerPerformanceScores?.performanceCards.filter(
          (item: any) => {
            return item.cardTitle === selectedCard;
          },
        );
      setSelectedCarddata(filterData);
    }
  }, [player, selectedCard]);

  const getProgressColor = (score?: number) => {
    if (score === undefined) return "";
    if (score > 85) return "#16C265";
    if (score > 75) return "#DBFF00";
    return "#F11E84";
  };

  return (
    <div className="mt-10">
      {player?.playerPerformanceScores?.performanceCards && (
        <PerfomanceScoreTrand
          percentageChange={
            player?.playerPerformanceScores?.performanceCards[0]?.scoreDelta
          }
          weeklyPerformanceScores={
            player?.playerPerformanceScores?.performanceCards[0]
              ?.weeklyPerformanceScores || {}
          }
          percentage={
            player?.playerPerformanceScores?.performanceCards[0]?.score
          }
          circularBarColor={
            player?.playerPerformanceScores?.performanceCards[0]?.cardColor
          }
          handlePerfomanceModal={handlePerfomanceModal}
        />
      )}
      <Paragraph
        text={selectedCardData[0]?.blurbShort}
        className="text-[10.5px] sm:text-xs md:text-base lg:text-[18.61px] opacity-70"
        ParagraphType="Paragraph22"
        color="textPrimary"
      />
      <Paragraph
        text={selectedCardData[0]?.blurbLong}
        className="text-[10.5px] sm:text-xs md:text-base lg:text-[18.61px] opacity-70 mt-2"
        ParagraphType="Paragraph22"
        color="textPrimary"
      />
      <div className="mt-6 grid lg:grid-cols-2 lg:gap-x-10 md:gap-x-5">
        {mapWithFallback(selectedCardData[0]?.statCategories).map(
          (category: any, index: number) => (
            <StatsSection key={index} {...category} />
          ),
        )}
      </div>
    </div>
  );
};

export default PlayerScores;
